
<template>
  <section class="ICreated">
    <iq-card body-class="text-center" style="height: 400px;">
      <div style="height: 78px">
        <img
          :src="groupInfo.background"
          style="display: block; width: 100%; height: 100%; object-fit: cover"
        />
      </div>
      <div class="card_body text-center pt-3 pb-3">
        <div
          style="cursor: pointer"
          @click="
            $router.push({
              path: '/group-detail',
              query: { id: groupInfo.id },
            })
          "
        >
          <div class="group-icon">
            <img
              :src="groupInfo.headimg"
              class="rounded-circle img_fit avatar-120"
            />
          </div>
          <div class="group-info pt-3 pb-3">
            <b-link>
              <h4 class="ml-3 mr-3" style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap" :title="groupInfo.group_name">
                {{ groupInfo.group_name }}
              </h4>
            </b-link>
          </div>
          <div class="group-details d-inline-block pb-3">
            <ul
              class="d-flex align-items-center justify-content-between list-inline m-0 p-0"
            >
              <li class="pl-3 pr-3">
                <!-- <p class="mb-0">Post</p> -->
                <p class="mb-0">{{$t('Club.bcc@post')}}</p>
                <h6>{{ groupInfo.post_total }}</h6>
              </li>
              <li class="pl-3 pr-3">
             <!--    <p class="mb-0">Member</p> -->
                  <p class="mb-0">{{$t('Club.95c@member')}}</p>
                <h6>{{ groupInfo.member_total }}</h6>
              </li>
            </ul>
          </div>
          <div class="group-member mb-3">
            <div class="iq-media-group">
              <a
                v-for="(item, index) in groupInfo.memberimg"
                :key="index"
                href="#"
                class="iq-media"
              >
                <img
                  class="img-fluid avatar-40 rounded-circle"
                  :src="item"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
        <div class="m-3">
          <slot></slot>
        </div>
      </div>
    </iq-card>
    <div class="disClock" v-if="groupInfo.is_open!=1">
      <p>{{groupInfo.reason}}</p>
    </div>
  </section>
</template>
<script>
export default {
  name: '',
  props: ['groupInfo'],
  data () {
    return {
    }
  },
  computed: {

  },
  watch: {},
  created () {
  },
  methods: {

  },

}
</script>
<style lang='scss' scoped>
@media (min-width: 600px){
  .disClock, .iq-card{
    width: 313px;
  }
}
@media (max-width: 600px){
  .disClock, .iq-card{
    width: 100%;
  }
}
.disClock{
  height: 400px;
  position: absolute;
  background: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  top:0;
  opacity: .4;
  &:hover{
    background: #333;
    opacity: .6;
  }
  p{
    color:#fff;
    padding: 40px 20px;
  }
}
</style>
