<template>
  <section class="Group">
    <!-- 页面 header 通屏大图 -->
    <FullBanner :bannerURL="$route.meta.img" :moduleName="$route.meta.name">
      <template v-slot:customFill>
        <iq-card class="iq-card">
          <section class="container">
            <div class="col-12 p-0">
              <div class="w-100 py-4 d-flex justify-content-between">
                <div class="d-flex flex-grow-1 search-box">
                  <input v-model="search" type="text" class="form-control" :placeholder="$t('Club.d37@search_club')"
                    @keyup.enter="searchGroup" />
                  <img src="@/assets/images/course/search.png" alt="" @click="searchGroup" />
                </div>
                <div class="ml-3">
                  <button type="button" style="
                      color: #50b5ff;
                      border: 1px solid #50b5ff;
                      border-radius: 10px;
                    " class="btn h-100" v-b-modal.createAGroup>
                    <!-- @click="active = 'created'" -->
                    {{ $t("Club.9a0@create_a_club") }}
                  </button>
                </div>
              </div>
            </div>
            <div>
              <el-select v-model="country" @change="changeCountry()" clearable
                style="width: 300px; border-radius: 10px" filterable >
                <el-option value="">All</el-option>
                <el-option v-for="item of countryList" :value="item.id" :key="item.id" :label="item.name">
                </el-option>
              </el-select>
            </div>
            <div class="col-12 p-0">
              <tab-nav :tabs="true">
                <!-- I created -->
                <tab-nav-items :active="active == 'explore'" href="#explore" :title="$t('Club.295@explore')"
                  @click.native="(page = 1), getGroupList()" />
                <tab-nav-items :active="active == 'created'" href="#created" :title="$t('Club.261@created')"
                  @click.native="(page = 1), getCreatedGroupList()" />
                <!-- I joined -->
                <tab-nav-items :active="active == 'join'" href="#joined" :title="$t('Club.2c2@joined')"
                  @click.native="(page = 1), getIJoinGroupList()" />
              </tab-nav>
            </div>
          </section>
        </iq-card>
      </template>
    </FullBanner>

    <div class="container">
      <tab-content>
        <!-- explore -->
        <tab-content-item :active="active == 'explore'" id="explore">
          <SkeletonGroup v-if="loading" />
          <b-row v-if="exploreList.length&&!loading">
            <b-col v-for="(item, index) in exploreList" :key="index" sm="12" md="6" lg="4">
              <GroupCard :groupInfo="item">
                <button class="btn d-block w-100 mb-3" :style="{
                    background: item.visibility == 1 ? '#286591' : '#4fb6ff',
                    color: '#fff',
                  }" @click="groupCardbBtn(item)">
                  {{ item.visibility == 1 ? "Apply" : "Join" }}
                </button>
              </GroupCard>
            </b-col>
          </b-row>
          <b-row v-if="!exploreList.length" class="d-flex justify-content-center" style="background:#fff;margin-top: 60px">
            <img style="width:250px;" src="../../assets/images/group/empty1.png" alt="" v-if="!loading" />
          </b-row>
        </tab-content-item>

        <tab-content-item :active="active == 'created'" id="created">
          <SkeletonGroup v-if="loading" />
          <b-row v-if="createdList.length&&!loading">
            <b-col v-for="(item, index) in createdList" :key="index" sm="12" md="6" lg="4">
              <GroupCard :groupInfo="item">
                <button class="btn d-block w-100 mb-3" :style="{
                    background: item.is_join ? '#286591' : '#E3E3E3',
                    color: '#fff',
                  }" @click="dissolveGroup(item)">
                  <!-- {{ $t("Club.f9a@dissolve") }} -->
                  Dissolve
                </button>
              </GroupCard>
            </b-col>
          </b-row>
          <b-row v-if="!createdList.length" class="d-flex justify-content-center" style="background:#fff;margin-top: 60px">
            <img style="width:250px;" src="../../assets/images/group/empty1.png" alt="" v-if="!loading" />
          </b-row>
        </tab-content-item>
        <tab-content-item :active="active == 'join'" id="join">
          <SkeletonGroup v-if="loading" />
          <b-row v-if="joinList.length&&!loading">
            <b-col v-for="(item, index) in joinList" :key="index" sm="12" md="6" lg="4">
              <GroupCard :groupInfo="item">
                <button class="btn d-block w-100" :style="{
                    background: item.is_join ? '#286591' : '#E3E3E3',
                    color: '#fff',
                  }" @click="
                    item.is_create ? dissolveGroup(item) : exitSelfGroup(item)
                  ">
                  <!-- {{ item.is_create ? "Dissolve" : "Quit" }} -->
                  {{
                  item.is_create
                  ? $t("Club.f9a@dissolve")
                  : $t("Club.123@qult")
                  }}
                </button>
              </GroupCard>
            </b-col>
          </b-row>
          <b-row v-if="!joinList.length" class="d-flex justify-content-center" style="background:#fff;margin-top: 60px">
            <img style="width:250px;" src="../../assets/images/group/empty1.png" alt="" v-if="!loading" />
          </b-row>
        </tab-content-item>
      </tab-content>
      <el-pagination background layout="prev, pager, next" pager-count="3" :page-size="size" :total="totalNum"
        style="text-align: right" @current-change="changePage" :current-page="page" hide-on-single-page>
      </el-pagination>
    </div>

    <b-modal id="createAGroup" centered :title="$t('Club.9a0@create_a_club')" hide-footer>
      <section class="createAGroupModal">
        <!-- <div class="img_box">
          <div style="border: 1px solid #f1f1f1">
            <img style="" ref="imgAva" :src="form.headimg" alt="" />
          </div>
          <div class="edit-pen">
            <el-upload
              class="avatar-uploader"
              action="no"
              :show-file-list="false"
              :http-request="uploadAvatar"
            >
              <i class="las la-pen"></i>
            </el-upload>
          </div>
        </div> -->

        <b-form class="formContent">
          <b-form-group label="Cover">
            <el-upload class="avatar-uploader mr-2" style="
                width: 90px;
                height: 90px;
                display: inline-block;
                border: 1px dashed #cecece;
                border-radius: 5px;
                text-align: center;
              " action="no" :show-file-list="false" :http-request="uploadAvatar">
              <img style="width: 90px; height: 90px" src="../../assets/images/albums/camera.png" alt="" />
            </el-upload>
            <div v-if="form.headimg" class="backImag" style="display: inline-block">
              <img style="width: 90px; height: 90px; border-radius: 5px" :src="form.headimg" alt="" />
              <img class="closeImg" @click="delImage(1)" src="@/assets/images/public/close.png" alt="" />
            </div>
          </b-form-group>
          <b-form-group label="Banner">
            <el-upload class="avatar-uploader mr-2" action="no" style="
                width: 180px;
                display: inline-block;
                border: 1px dashed #cecece;
                border-radius: 5px;
                text-align: center;
              " :show-file-list="false" :http-request="uploadBackground">
              <img style="width: 90px; height: 90px" src="../../assets/images/albums/camera.png" alt="" />
            </el-upload>
            <div class="backImag" v-if="form.background" style="display: inline-block">
              <img style="width: 180px; height: 90px; border-radius: 5px" :src="form.background" alt="" />
              <img class="closeImg" @click="delImage(2)" src="@/assets/images/public/close.png" alt="" />
            </div>
          </b-form-group>
          <!-- <b-form-group :label="$t('Club.a0a@club_name')"> -->
          <b-form-group label="Interest Club Name">
            <b-form-input v-model="form.group_name" type="text"></b-form-input>
          </b-form-group>
          <b-form-group label="Privacy Settings">
            <select class="form-control birthdaySelect" v-model="form.visibility">
              <option :value="1">{{ $t("Club.aa1@private") }}</option>
              <option :value="2">{{ $t("Club.c8e@public") }}</option>
            </select>
          </b-form-group>
          <b-form-group label="Country">
            <!-- <CountrySelection
              @returnPrams="getAddressParams"
              :grandsonClassName="['col-md-6']"
              CountryLabel="Country"
              RegionLabel="Region"
            /> -->
            <select class="form-control" v-model="form.country_id" style="width: 300px">
              <!-- <option value="">All</option> -->
              <option v-for="item of countryList" :value="item.id" :key="item.id">
                {{ item.name }}
              </option>
            </select>
          </b-form-group>
          <b-form-group label="Introduction">
            <quill-editor ref="myQuillEditor" class="QuillEditor" v-model="form.introduce" :options="editorOption" />
          </b-form-group>
          <b-form-group label="Interest Club Rules">
            <quill-editor ref="myQuillEditor" class="QuillEditor" v-model="form.rules" :options="editorOption" />
          </b-form-group>
        </b-form>
        <div class="d-flex justify-content-end">
          <b-button class="btnOrange mr-2" @click="$bvModal.hide('createAGroup')">
            {{ $t("Club.57b@cancel") }}
          </b-button>
          <b-button variant="primary" @click="confirmModal">
            {{ $t("Club.bbc@save") }}
          </b-button>
        </div>
      </section>
    </b-modal>
    <!-- <b-modal
      id="picModal"
      :title="$t('Club.771@select_image_focus')"
      hide-footer
    >
      <div style="width: 400px; height: 400px; margin: 0 auto">
        <vueCropper
          ref="cropper"
          :img="option.img"
          :outputSize="1"
          :autoCrop="true"
          :canScale="true"
          :canMoveBox="false"
          :fixedNumber="[1, 1]"
          :fixedBox="true"
          canMove
          fixed
        ></vueCropper>
      </div>
      <div class="d-flex justify-content-end mt-2">
        <b-button class="mr-2" @click="$bvModal.hide('picModal')">
          {{ $t("Club.57b@cancel") }}</b-button
        >
        <b-button variant="primary" @click="catAvatar">
          {{ $t("Club.d5e@confirm") }}</b-button
        >
      </div>
    </b-modal> -->
  </section>
</template>
<script>
import SkeletonGroup from './components/SkeletonGroup'
import FullBanner from '@/components/ComComponents/FullBanner'
import GroupCard from './components/groupCard'
import CountrySelection from '@/components/ComComponents/CountrySelection'
import { mapState } from 'vuex'

import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
export default {
  name: 'Group',
  components: {
    FullBanner,
    GroupCard,
    CountrySelection,
    quillEditor,
    SkeletonGroup
  },

  data () {
    return {
      loading: true,
      active: 'explore',
      search: '',
      countryList: [],
      country: '',

      exploreList: [],
      createdList: [],
      joinList: [],

      // created-modal
      option: {
        img: ''
      },
      form: {
        headimg: '',
        background: '',
        group_name: '',
        visibility: '',
        country_id: '',
        // region_id: "",
        introduce: '',
        rules: ''
      },
      editorOption: {
        // Some Quill options...
      },
      totalNum: 10,
      page: 1,
      size: 6,

      editorOption: {
        placeholder: 'Insert Text Here',
        modules: {
          toolbar: [
            ['bold'], // 加粗
            [
              {
                list: 'ordered'
              },
              {
                list: 'bullet'
              }
            ], // 列表
            [
              {
                size: ['small', false, 'large', 'huge']
              }
            ], // 字体大小
            [
              {
                color: []
              },
              {
                background: []
              }
            ], // 字体颜色，字体背景颜色
            [
              {
                font: []
              }
            ], // 字体
            [
              {
                align: []
              }
            ] // 对齐方式
          ]
        }
      }
    }
  },

  created () { },
  mounted () {
    this.getGroupList()
    this.getCountry()
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      this.form.headimg = ''
      this.form.background = ''
      this.form.group_name = ''
      this.form.visibility = ''
      this.form.country_id = ''
      this.form.rules = ''
      this.form.introduce = ''
      // this.form.region_id = "";
    })
  },
  methods: {
    delImage (type) {
      if (type == 1) {
        this.form.headimg = ''
      } else {
        this.form.background = ''
      }
    },
    changeCountry () {
      this.page = 1
      this.size = 6
      this.getGroupList()
    },
    getCountry () {
      this.$http.getAddress({ level: 2 }).then((res) => {
        if (res.status == 200) {
          this.countryList = res.data
        }
      })
    },
    getAddressParams (log) {
      // console.log(log)
      this.form.country_id = log.countryid
      // this.form.region_id = log.regionid;
    },

    // 上传头像
    uploadAvatar (params) {
      var formData = new FormData()
      formData.append('file', params.file)
      this.$http.uploadImage(formData).then((res) => {
        if (res.status == 200) {
          this.form.headimg = res.data[0]
        }
      })
      // var reader = new FileReader();
      // reader.readAsDataURL(file.file);
      // let that = this;
      // reader.onload = function (e) {
      // that.option.img = this.result;
      // console.log();
      // that.form.headimg = this.result
      // that.$bvModal.show("picModal");
      // };
    },
    uploadBackground (params) {
      // var reader = new FileReader();
      // reader.readAsDataURL(file.file);
      // let that = this;
      // reader.onload = function (e) {
      //   that.form.background = this.result
      // };
      var formData = new FormData()
      formData.append('file', params.file)
      this.$http.uploadImage(formData).then((res) => {
        if (res.status == 200) {
          this.form.background = res.data[0]
        }
      })
    },

    // catAvatar() {
    //   console.log("catcatcat");
    //   this.$refs.cropper.getCropBlob(async (blobFile) => {
    //     let formData = new FormData();
    //     let fileOfBlob = new File([blobFile], getName() + ".jpg");
    //     formData.append("file", fileOfBlob);
    //     let { data } = await this.$http.uploadImage(formData);
    //     this.form.headimg = data[0];
    //     this.$bvModal.hide("picModal");

    //     // 生成唯一标识
    //     function getName() {
    //       return +(
    //         parseInt(new Date().getTime() / 1000) +
    //         "" +
    //         Math.round(Math.random() * (9999 - 1000) + 1000)
    //       );
    //     }
    //   });
    // },

    // addGroup
    async confirmModal () {
      let { status } = await this.$http.addGroup({
        ...this.form
      })
      if (status == 200) {
        this.$bvModal.hide('createAGroup')
        this.getCreatedGroupList()
        this.active = 'created'
      }
    },

    searchGroup () {
      if (this.active == 'explore') {
        this.getGroupList()
      } else if (this.active == 'created') {
        this.getCreatedGroupList()
      } else {
        this.getIJoinGroupList()
      }
    },

    changePage (page) {
      this.page = page
      if (this.active == 'explore') {
        this.getGroupList()
      } else if (this.active == 'created') {
        this.getCreatedGroupList()
      } else {
        this.getIJoinGroupList()
      }
    },

    // explore
    getGroupList () {
      this.loading = true
      this.active = 'explore'
      this.$http
        .getGroupList({
          keyword: this.search,
          country_id: this.country,
          page: this.page,
          size: this.size
        })
        .then((res) => {
          let result = res.data.constructor == Array
          if (result) {
            this.exploreList = res.data.filter((item) => !item.is_join)
          } else {
            this.exploreList = res.data.data.filter((item) => !item.is_join)
            this.totalNum = res.data.total
          }
          setTimeout(() => {
            this.loading = false
          }, 0)
        })
    },

    // created
    getCreatedGroupList () {
      this.loading = true
      this.active = 'created'
      this.$http
        .getIAddGroupList({
          keyword: this.search,
          page: this.page,
          size: this.size
        })
        .then((res) => {
          this.createdList = res.data.data
          this.totalNum = res.data.total
          setTimeout(() => {
            this.loading = false
          }, 0)
        })
    },

    // join
    getIJoinGroupList () {
      this.loading = true
      this.active = 'join'
      this.$http
        .getIJoinGroupList({
          type: 1,
          keyword: this.search,
          page: this.page,
          size: this.size
        })
        .then((res) => {
          this.joinList = res.data.data
          this.totalNum = res.data.total
          setTimeout(() => {
            this.loading = false
          }, 0)
        })
    },

    // 直接加入
    groupCardbBtn (item) {
      // 直接加入加入俱乐部
      this.$http
        .joinGroup({
          group_id: item.id
        })
        .then((res) => {
          this.getGroupList()
        })
    },

    // 解散Club
    dissolveGroup (item) {
      this.$confirm(
        // 'Are you sure you want to dissolve the club?'
        this.$t('Club.643@are_you_sure_you_wan'),
        this.$t('Club.00c@tips'),
        {
          distinguishCancelAndClose: true,
          confirmButtonText: this.$t('Club.d5e@confirm'),
          cancelButtonText: this.$t('Club.57b@cancel'),
          type: 'warning'
        }
      ).then(() => {
        this.$http
          .dissolveGroup({
            group_id: item.id
          })
          .then((res) => {
            if (res.status == 200) {
              this.getCreatedGroupList()
            }
          })
      })
    },

    // 退出
    exitSelfGroup (item) {
      this.$confirm(
        // 'Are you sure you want to exit the club?',
        this.$t('Club.67e@are_you_sure_you_wan'),
        this.$t('Club.00c@tips'),
        {
          distinguishCancelAndClose: true,
          confirmButtonText: this.$t('Club.d5e@confirm'),
          cancelButtonText: this.$t('Club.57b@cancel'),
          type: 'warning'
        }
      ).then(() => {
        this.$http
          .exitSelfGroup({
            group_id: item.id
          })
          .then((res) => {
            if (res.status == 200) {
              this.getIJoinGroupList()
            }
          })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .Group {
    min-height: 100vh;
  }

  .backImag {
    position: relative;

    .closeImg {
      position: absolute;
      right: -5px;
      top: -5px;
    }
  }

  @media screen and (min-width: 992px) {
    ::v-deep #createAGroup .modal-dialog {
      max-width: 800px !important;
    }
  }

  .QuillEditor {
    width: 100%;

    ::v-deep .ql-container {
      height: 182px;
    }
  }

  ::v-deep .el-select .el-input__inner {
    border-radius: 10px;
    height: 45px;
  }

  .search-box {
    // padding: 0 8px;
    position: relative;

    img {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  #createAGroup {
    .createAGroupModal {
      .img_box {
        width: 150px;
        height: 150px;
        margin: 0 auto;
        position: relative;

        >div {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          overflow: hidden;

          >img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        >.edit-pen {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          overflow: hidden;
          z-index: 9999;
          text-align: center;
          line-height: 30px;
          background-color: #50b5ff;
          color: #fff;
          position: absolute;
          bottom: 17px;
          left: 118px;

          .el-upload {
            position: relative;

            i {
              position: absolute;
              top: 3px;
              left: 5px;
            }
          }
        }
      }

      .formContent {
        #Introduction {
          height: 85px;
          line-height: 20px;
        }
      }
    }
  }
</style>
